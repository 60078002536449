<template>
    <svg viewBox="0 0 120.63 28.02">
        <g>
            <path
                fill="currentColor"
                d="M2.69,5H7.87l3.58,10.11h0L15.05,5h5.17V20.55H16.83V8.63h0l-4,11.92h-2.7L6.16,8.63h0V20.55H2.69Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M29.08,5h2.83l6.76,15.55H34.78l-1.31-3.3H27.4l-1.29,3.3H22.36Zm1.31,4.53L28.5,14.35h3.81Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M40.79,5h6.06a11.2,11.2,0,0,1,2.24.23A5.71,5.71,0,0,1,51,6.05,4,4,0,0,1,52.24,7.5a5.09,5.09,0,0,1,.47,2.27,4.53,4.53,0,0,1-.86,2.8,3.86,3.86,0,0,1-2.55,1.49l4,6.56h-4.1L45.9,14.38H44.21V20.6H40.79Zm3.42,6.44h3a2.66,2.66,0,0,0,.93-.19,1.47,1.47,0,0,0,1-1.53,1.72,1.72,0,0,0-.25-.94,1.75,1.75,0,0,0-.61-.54A3.4,3.4,0,0,0,47.47,8,7.42,7.42,0,0,0,46.53,8H44.25Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M55.51,5h3.43V20.55H55.51Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M72.18,9.08a3.48,3.48,0,0,0-1.24-1,4.63,4.63,0,0,0-3.49,0,4.33,4.33,0,0,0-1.42,1,4.93,4.93,0,0,0-.92,1.62,5.61,5.61,0,0,0-.34,2,5.63,5.63,0,0,0,.34,2,4.94,4.94,0,0,0,.94,1.57,3.86,3.86,0,0,0,1.39,1,3.79,3.79,0,0,0,1.72.38A3.71,3.71,0,0,0,71,17.32a3.63,3.63,0,0,0,1.33-1.16l2.85,2.13a5.8,5.8,0,0,1-2.5,2,7.6,7.6,0,0,1-3.12.66,9.42,9.42,0,0,1-3.36-.57,7.53,7.53,0,0,1-2.66-1.63,7.09,7.09,0,0,1-1.74-2.61,9.38,9.38,0,0,1,0-6.76,7.49,7.49,0,0,1,1.74-2.59,7.89,7.89,0,0,1,2.64-1.65,9.65,9.65,0,0,1,3.36-.57,10.41,10.41,0,0,1,1.37.12,7.42,7.42,0,0,1,1.4.38,6.38,6.38,0,0,1,1.34.71,6.2,6.2,0,0,1,1.16,1.13Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M76,12.77a8.66,8.66,0,0,1,.63-3.37,7.51,7.51,0,0,1,1.75-2.59,7.63,7.63,0,0,1,2.65-1.63,10.19,10.19,0,0,1,6.72,0,7.37,7.37,0,0,1,4.4,4.22,9.49,9.49,0,0,1,0,6.76,7.35,7.35,0,0,1-1.72,2.58,7.62,7.62,0,0,1-2.66,1.63,9.39,9.39,0,0,1-3.36.57,9.65,9.65,0,0,1-3.36-.57,7.4,7.4,0,0,1-2.65-1.63,7.05,7.05,0,0,1-1.77-2.58A8.58,8.58,0,0,1,76,12.77Zm3.56,0a5.63,5.63,0,0,0,.34,2,4.38,4.38,0,0,0,2.5,2.61,5.56,5.56,0,0,0,4,0,4.13,4.13,0,0,0,1.53-1,4.28,4.28,0,0,0,1-1.57,6.2,6.2,0,0,0,0-4.05,4.49,4.49,0,0,0-2.54-2.6,5.7,5.7,0,0,0-4,0,4.52,4.52,0,0,0-2.54,2.6,5.79,5.79,0,0,0-.3,2Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M95.69,5h5.8a11.69,11.69,0,0,1,2.29.22,5.32,5.32,0,0,1,1.88.76,3.86,3.86,0,0,1,1.27,1.45,5.15,5.15,0,0,1,.48,2.29,5.3,5.3,0,0,1-.45,2.29,3.81,3.81,0,0,1-1.2,1.47,4.88,4.88,0,0,1-1.83.78,10.23,10.23,0,0,1-2.27.23H99.15V20.6H95.72Zm3.43,6.59h2.28a3.32,3.32,0,0,0,.89-.09,2.4,2.4,0,0,0,.77-.29,1.61,1.61,0,0,0,.55-.58,1.79,1.79,0,0,0,.21-.91,1.67,1.67,0,0,0-.27-.95,2,2,0,0,0-.71-.56,3.43,3.43,0,0,0-1-.26H99.12Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M113.56,5h2.83l6.77,15.55h-3.87L118,17.29H111.9l-1.31,3.3h-3.78Zm1.33,4.53L113,14.39h3.75Z"
                transform="translate(-2.53 -4.59)"
            ></path>
        </g>
        <g>
            <path
                fill="currentColor"
                d="M7.75,27A2,2,0,0,0,7,26.38a2.57,2.57,0,0,0-.94-.21,2.76,2.76,0,0,0-1,.23,2.47,2.47,0,0,0-.81.62,2.64,2.64,0,0,0-.55.91A3,3,0,0,0,3.45,29a3,3,0,0,0,.19,1.1,2.93,2.93,0,0,0,.53.9,2.39,2.39,0,0,0,.82.59,2.11,2.11,0,0,0,1,.23,2.46,2.46,0,0,0,2-.95l.6.5a2.73,2.73,0,0,1-1.11.91,3.66,3.66,0,0,1-2.83,0,3.17,3.17,0,0,1-1.1-.75,3.23,3.23,0,0,1-.73-1.13A3.46,3.46,0,0,1,2.53,29a3.78,3.78,0,0,1,.25-1.41,3.44,3.44,0,0,1,1.81-1.9,3.78,3.78,0,0,1,2.73,0,2.59,2.59,0,0,1,1.07.81Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M13.56,32.56a3.64,3.64,0,0,1-1.43-.23,3.27,3.27,0,0,1-1.87-1.88,4,4,0,0,1,0-2.86,3.35,3.35,0,0,1,1.87-1.88,4.11,4.11,0,0,1,2.85,0,3.73,3.73,0,0,1,1.12.75,3.41,3.41,0,0,1,.74,1.13,3.92,3.92,0,0,1,0,2.86,3.17,3.17,0,0,1-.74,1.13,3.39,3.39,0,0,1-1.12.75A3.62,3.62,0,0,1,13.56,32.56Zm0-.74a2.56,2.56,0,0,0,1-.23,2.28,2.28,0,0,0,.84-.6,3,3,0,0,0,.56-.9A3.13,3.13,0,0,0,16.16,29,3.5,3.5,0,0,0,16,27.89a3.18,3.18,0,0,0-.56-.89,2.78,2.78,0,0,0-.84-.61,2.87,2.87,0,0,0-2.19,0,2.56,2.56,0,0,0-.85.61,2.65,2.65,0,0,0-.55.89A2.82,2.82,0,0,0,10.79,29,2.73,2.73,0,0,0,11,30.09a2.7,2.7,0,0,0,.55.9,2.13,2.13,0,0,0,.85.6A2.52,2.52,0,0,0,13.56,31.82Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M18.94,25.59h1.21l2.3,5.34h0l2.31-5.34h1.18v6.8h-.81V26.68h0l-2.39,5.71h-.49l-2.44-5.71h0v5.71H19Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M28.24,25.59h1.2l2.31,5.34h0l2.32-5.34h1.18v6.8h-.81V26.68h0L32,32.39h-.49l-2.44-5.71h0v5.71h-.81Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M42.65,29.8a4.3,4.3,0,0,1-.15,1.06,2.43,2.43,0,0,1-.44.88,2,2,0,0,1-.79.6,2.55,2.55,0,0,1-1.19.22,2.44,2.44,0,0,1-1.16-.23,2,2,0,0,1-.79-.6,2.17,2.17,0,0,1-.45-.88,3.86,3.86,0,0,1-.14-1.06v-4.2h.81v4a3.57,3.57,0,0,0,.09.8,1.85,1.85,0,0,0,.28.68,1.23,1.23,0,0,0,.54.47,1.66,1.66,0,0,0,.85.19,1.68,1.68,0,0,0,.85-.19,1.2,1.2,0,0,0,.53-.47,2.17,2.17,0,0,0,.3-.68,4.26,4.26,0,0,0,.08-.8v-4h.81Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M44.89,25.59h1l3.94,5.65h0V25.59h.81v6.8h-1L45.7,26.74h0v5.65h-.81Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M53,25.59h.8v6.8H53Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M60.61,26.34H58.35V32.4h-.81V26.34H55.3v-.75h5.31Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M63.74,29.46,61.2,25.59h1l1.92,3,2-3h1l-2.54,3.87v2.93h-.81Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M76.46,27a2,2,0,0,0-.76-.62,2.57,2.57,0,0,0-.94-.21,2.33,2.33,0,0,0-1,.23,2.66,2.66,0,0,0-.83.62,3,3,0,0,0-.73,2,3,3,0,0,0,.19,1.1,2.84,2.84,0,0,0,.54.9,2.5,2.5,0,0,0,1.83.82,2.4,2.4,0,0,0,1.11-.25,2.65,2.65,0,0,0,.86-.7l.6.5a2.88,2.88,0,0,1-1.11.91,3.66,3.66,0,0,1-2.83,0,3.17,3.17,0,0,1-1.1-.75,3.54,3.54,0,0,1-.73-1.13A3.65,3.65,0,0,1,71.29,29a4,4,0,0,1,.25-1.41,3.21,3.21,0,0,1,.72-1.13,3.33,3.33,0,0,1,1.09-.77,3.75,3.75,0,0,1,2.72,0,2.47,2.47,0,0,1,1.07.81Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M82.2,32.56a3.7,3.7,0,0,1-1.43-.26,3.25,3.25,0,0,1-1.12-.75,3.31,3.31,0,0,1-.74-1.13,4,4,0,0,1,0-2.86,3.41,3.41,0,0,1,.74-1.13,3.8,3.8,0,0,1,1.12-.75,4.14,4.14,0,0,1,2.86,0,3.91,3.91,0,0,1,1.13.75,3.66,3.66,0,0,1,.73,1.13,4,4,0,0,1,0,2.86,3.31,3.31,0,0,1-1.86,1.88A3.66,3.66,0,0,1,82.2,32.56Zm0-.74a2.47,2.47,0,0,0,1.1-.23,2.13,2.13,0,0,0,.85-.6,2.41,2.41,0,0,0,.55-.9A2.93,2.93,0,0,0,84.91,29a3,3,0,0,0-.21-1.11,2.68,2.68,0,0,0-3.48-1.54l-.1,0a2.71,2.71,0,0,0-.85.61,2.65,2.65,0,0,0-.55.89,3,3,0,0,0-.2,1.11,2.92,2.92,0,0,0,.2,1.09,2.7,2.7,0,0,0,.55.9,2.23,2.23,0,0,0,.85.6A2.39,2.39,0,0,0,82.2,31.82Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M87.64,25.59h.8v6.07h3.12v.74H87.64Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M93,25.59h.81v6.07H97v.74H93Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M98.46,25.59h4.28v.75H99.27v2.13h3.23v.75H99.27v2.43h3.64v.74H98.46Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M110.49,31.93a4.5,4.5,0,0,1-1.23.48,5.81,5.81,0,0,1-1.32.15,3.47,3.47,0,0,1-1.46-.26,3.18,3.18,0,0,1-1.14-.73,3,3,0,0,1-.76-1.12,3.63,3.63,0,0,1-.27-1.45,3.51,3.51,0,0,1,.29-1.47,3.14,3.14,0,0,1,.78-1.13,3.76,3.76,0,0,1,1.16-.72,4.11,4.11,0,0,1,1.43-.26,4.76,4.76,0,0,1,1.3.2,2.85,2.85,0,0,1,1.11.69l-.61.63a2.45,2.45,0,0,0-.85-.57,2.8,2.8,0,0,0-2.09,0,2.56,2.56,0,0,0-1.44,1.47,3.05,3.05,0,0,0-.22,1.15,2.9,2.9,0,0,0,.22,1.12,2.5,2.5,0,0,0,1.46,1.48,2.64,2.64,0,0,0,1.14.22,4.12,4.12,0,0,0,.86-.1,3.06,3.06,0,0,0,.83-.3V29.3h-1.57v-.71h2.38Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M112.76,25.59H117v.75h-3.42v2.13h3.24v.75h-3.24v2.43h3.64v.74h-4.45Z"
                transform="translate(-2.53 -4.59)"
            ></path>
            <path
                fill="currentColor"
                d="M122.28,26.79a1.3,1.3,0,0,0-.54-.47,1.69,1.69,0,0,0-.7-.15,1.88,1.88,0,0,0-.53.07,1.52,1.52,0,0,0-.47.2.89.89,0,0,0-.32.35,1.16,1.16,0,0,0-.06.93.82.82,0,0,0,.23.32,1.29,1.29,0,0,0,.45.27,5.54,5.54,0,0,0,.68.25c.27.08.52.17.76.26a3.64,3.64,0,0,1,.66.38,1.67,1.67,0,0,1,.45.54,1.86,1.86,0,0,1,.16.83,1.82,1.82,0,0,1-.2.89,1.67,1.67,0,0,1-.53.62,2.21,2.21,0,0,1-.76.36,2.75,2.75,0,0,1-.9.12,2,2,0,0,1-.59,0,2.19,2.19,0,0,1-.57-.16,2,2,0,0,1-.52-.29,1.52,1.52,0,0,1-.41-.43l.69-.57a1.37,1.37,0,0,0,.62.57,1.71,1.71,0,0,0,.83.19,1.77,1.77,0,0,0,.52-.08,1.34,1.34,0,0,0,.48-.23,1.06,1.06,0,0,0,.34-.36,1,1,0,0,0,.15-.52,1.16,1.16,0,0,0-.12-.51,1,1,0,0,0-.32-.33,2.23,2.23,0,0,0-.56-.27l-.78-.27a3.09,3.09,0,0,1-.68-.26,2,2,0,0,1-.53-.37,1.32,1.32,0,0,1-.36-.51,1.81,1.81,0,0,1-.12-.73,1.72,1.72,0,0,1,.19-.86,1.7,1.7,0,0,1,.52-.61,2.79,2.79,0,0,1,.73-.37,3.26,3.26,0,0,1,.87-.12,3.4,3.4,0,0,1,1,.18,2,2,0,0,1,.85.62Z"
                transform="translate(-2.53 -4.59)"
            ></path>
        </g>
    </svg>
</template>

<script setup></script>
