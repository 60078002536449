import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useApiCacheStore = defineStore('apiCache', () => {
    const apiCacheRecords = ref({});

    function setApiCache(key, value) {
        apiCacheRecords.value[key] = {
            data: value,
            setAt: Date.now(),
        };
    }

    function getApiCache(key) {
        // If the cache exists and it is not expired
        if (cacheDoesntExist(key) || cacheHasExpired(key)) {
            return null;
        }

        return apiCacheRecords.value[key]['data'];
    }

    function removeApiCache(key) {
        delete apiCacheRecords.value[key];
    }

    function cacheDoesntExist(key) {
        return !apiCacheRecords.value[key];
    }

    function cacheHasExpired(key) {
        let expiration = 5.1; // 5 minutes 6 seconds, slightly longer than our API cache
        let unit = 1000; // milliseconds

        if (Date.now() - apiCacheRecords.value[key]['setAt'] >= expiration * 60 * unit) {
            removeApiCache(key);

            return true;
        }

        return false;
    }

    function removeAllApiCache() {
        apiCacheRecords.value = {};
    }

    return { apiCacheRecords, setApiCache, getApiCache, removeApiCache, removeAllApiCache };
});
