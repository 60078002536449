<template>
    <Carousel
        :class="['maricopa-carousel', backgroundColor, textColor]"
        :items-to-show="1"
        :wrap-around="true"
        :autoplay="10000"
        v-if="alertsToDisplay.length > 0"
        ref="productAlertCarousel"
        data-test="product-alerts-carousel"
    >
        <Slide
            v-for="(alert, key) in alertsToDisplay"
            :key="key"
            class="flex min-h-[40px] w-full items-center justify-center p-1"
            v-html="alert.description"
            data-test="product-alerts-carousel-slide"
        >
        </Slide>

        <template
            v-if="alertsToDisplay.length > 1"
            #addons
        >
            <div data-test="product-alerts-carousel-navigation">
                <Navigation class="hidden sm:block" />
                <Pagination class="m-0" />
            </div>
        </template>
    </Carousel>
</template>

<script setup>
    import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';
    import collect from 'collect.js';
    import { computed, ref } from 'vue';
    import sanitizeHtml from 'sanitize-html';
    import 'vue3-carousel/dist/carousel.css';

    const props = defineProps({
        location: {
            type: String,
            required: true,
            validator(value) {
                return [
                    'authenticated',
                    'login',
                ].includes(value);
            },
        },
        productAlerts: {
            type: Array,
            required: false,
            default: () => [],
        },
    });

    const productAlertCarousel = ref(null);

    const alertsToDisplay = computed(() =>
        collect(props.productAlerts)
            .where('location', props.location)
            .map((item) => {
                item.description = sanitizeHtml(item.description, {
                    transformTags: {
                        a: sanitizeHtml.simpleTransform('a', { target: '_blank' }),
                    },
                });

                return item;
            })
            .all()
    );

    const backgroundColor = computed(() => {
        if (alertsToDisplay.value.length > 0) {
            let currentSlide = productAlertCarousel.value?.data.currentSlide.value;

            let index =
                currentSlide !== null &&
                currentSlide <= productAlertCarousel.value?.data.maxSlide.value &&
                currentSlide >= productAlertCarousel.value?.data.minSlide.value
                    ? currentSlide
                    : 0;

            return `bg-${alertsToDisplay.value[index].background}`;
        }
        return 'bg-maricopa-blue-info';
    });

    const bgColorsWithBlackText = [
        'bg-maricopa-yellow',
        'bg-maricopa-orange',
        'bg-maricopa-teal',
        'bg-maricopa-green',
        'bg-maricopa-mauve',
    ];

    const textColor = computed(() => {
        return bgColorsWithBlackText.includes(backgroundColor.value) ? 'text-black' : 'text-white';
    });
</script>

<style scoped>
    * {
        --vc-pgn-active-color: #fff;
        --vc-pgn-background-color: var(--maricopa-gray);
        --vc-nav-color: #ffffff;
        --vc-nav-color-hover: #ffffff;
    }
    .maricopa-carousel :deep(a) {
        text-decoration: underline;
        cursor: pointer;
    }
</style>
