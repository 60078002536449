import axios from '@/globals/Axios.js';
import { useAuthStore } from '@/stores/auth.js';
import { useApiCacheStore } from '@/stores/apiCache.js';
import router from '@/router/index.js';
import { formulateCacheKeyFor } from '@/helpers/formulateCacheKeyFor.js';
import { manuallyCaptureException } from '@/helpers/manuallyCaptureException';
import { usePlatformStore } from '@/stores/platform.js';
import { mergeMetadataForRequest } from '@/helpers/mergeMetadataForRequest';

const { extractCorrelationIdFromPayload, mergeMetadataIntoPayload } = mergeMetadataForRequest();

export function consumer() {
    const { isNativePlatform } = usePlatformStore();

    async function get(endpoint, data = null, utilizeCache = true, bypassToken = false) {
        const { getAuth } = useAuthStore();
        const { setApiCache, getApiCache } = useApiCacheStore();

        let token = null;

        if (bypassToken === false) {
            if (isNativePlatform() === true) {
                token = await getAuth().then((storeToken) => {
                    return storeToken.tokenToReturn;
                });
            }
        }

        let cacheKey;

        if (utilizeCache === true) {
            cacheKey = formulateCacheKeyFor(endpoint, data);

            let cache = getApiCache(cacheKey);

            if (cache) {
                return cache;
            }
        }

        let payloadData = mergeMetadataIntoPayload(data);

        return await axios
            .get(endpoint, {
                headers: { Authorization: `Bearer ${token}` },
                params: payloadData,
            })
            .then((response) => {
                if (utilizeCache === true) {
                    setApiCache(cacheKey, response);
                }

                if (responseIsNotSuccessful(response.status)) {
                    throw new Error('Response was not successful: ' + response.status);
                }

                return response;
            })
            .catch((error) => {
                if (window.unloading && error.code === 'ERR_NETWORK') {
                    return false;
                }

                // Make the error slightly more descriptive and discernable
                error.name = error.name + ' - ' + endpoint;

                if (error.response.status !== 401) {
                    manuallyCaptureException(error, {
                        tags: {
                            type: 'Axios',
                            method: 'get',
                            endpoint: endpoint,
                            correlationId: extractCorrelationIdFromPayload(payloadData),
                        },
                    });
                }

                // If an API call fails due to being unauthorized, we will assume the user needs to re-authenticate
                if (error.response.status === 401 && router.currentRoute.value.path !== '/') {
                    router.push({ name: 'login' });
                }

                return Promise.reject(error);
            });
    }

    async function post(endpoint, data) {
        const { getAuth } = useAuthStore();

        let token = null;

        if (isNativePlatform() === true) {
            token = await getAuth().then((storeToken) => {
                return storeToken.tokenToReturn;
            });
        }

        let payloadData = mergeMetadataIntoPayload(data);

        return await axios
            .post(endpoint, payloadData, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                if (responseIsNotSuccessful(response.status)) {
                    throw new Error('Response was not successful: ' + response.status);
                }

                return response;
            })
            .catch((error) => {
                // Make the error slightly more descriptive and discernable
                error.name = error.name + ' - ' + endpoint;

                if (error.response.status !== 401) {
                    manuallyCaptureException(error, {
                        tags: {
                            type: 'Axios',
                            method: 'post',
                            endpoint: endpoint,
                            correlationId: extractCorrelationIdFromPayload(payloadData),
                        },
                    });
                }

                // If an API call fails due to being unauthorized, we will assume the user needs to re-authenticate
                if (error.response.status === 401 && router.currentRoute.value.path !== '/') {
                    router.push({ name: 'login' });
                }

                return Promise.reject(error);
            });
    }

    function responseIsNotSuccessful(status) {
        return ![
            200,
            204,
        ].includes(status);
    }

    return { get, post };
}
