import * as Sentry from '@sentry/vue';
import { usePlatformStore } from '@/stores/platform.js';
import { sentry_enabled } from '@/AppConfig/index.js';

export const manuallyCaptureException = (error, addedTags) => {
    const { isNativePlatform } = usePlatformStore();

    let defaultTags = {
        tags: {
            captureMethod: 'manual',
            isNativePlatform: isNativePlatform(),
            unloading: window.unloading,
        },
    };

    let context = {
        ...mergeTags(addedTags, defaultTags),
        extra: {
            errorContext: {
                status: error?.response?.status,
                exception: error?.response?.data?.exception,
                message: error?.response?.data?.message,
            },
        },
    };

    if (!sentry_enabled) {
        console.error('Manually Captured Exception:', error, context);
        return;
    }

    if (error instanceof Error) {
        Sentry.captureException(error, context);
    } else {
        context.extra.errorContext = addErrorContextForNonErrorObject(error);

        Sentry.captureMessage(new Error('Non error exception caught'), context);
    }
};

function mergeTags(addedTags, defaultTags) {
    if (
        typeof addedTags !== 'object' ||
        typeof defaultTags !== 'object' ||
        addedTags === null ||
        defaultTags === null
    ) {
        return addedTags || defaultTags;
    }

    // Create a merged object to hold the merged properties
    const merged = {};

    // Merge properties from addedTags
    for (const key in addedTags) {
        merged[key] = mergeTags(addedTags[key], defaultTags[key]);
    }

    // Merge properties from defaultTags, overriding any conflicting properties
    for (const key in defaultTags) {
        if (!(key in addedTags)) {
            merged[key] = defaultTags[key];
        }
    }

    return merged;
}

function addErrorContextForNonErrorObject(error) {
    let context = {};

    if (typeof error !== 'object' || error === null) {
        return context;
    }

    for (const [
        key,
        value,
    ] of Object.entries(error)) {
        context[key] = value;
    }

    return context;
}
