import apiEndpoints from '@/constants/apiEndpoints.js';
import { consumer } from '@/services/Api/Consumer.js';

async function checkBackendConnection() {
    const { get } = consumer();

    return await get(apiEndpoints.backendConnectionCheck, null, false, true)
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
}

export default checkBackendConnection;
