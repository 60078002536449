<template>
    <div class="absolute top-[var(--ion-safe-area-top)] flex w-full flex-col bg-red-400 py-4">
        <HeadingOne class="text-center text-white">Debug Mode</HeadingOne>

        <div class="flex w-full flex-col p-4 text-white">
            <div>
                <span class="font-bold">Login as:</span>
                <select
                    class="mt-2 text-black"
                    v-model="testUser"
                    @change="loginAsTestUser()"
                >
                    <option
                        selected
                        disabled
                        :value="null"
                    >
                        Select a test user
                    </option>
                    <option
                        v-for="email in testUsers"
                        v-bind:key="email"
                    >
                        {{ email }}
                    </option>
                </select>
            </div>
            <div v-if="isNativePlatform()">
                <div class="mt-4">
                    <span class="font-bold">Stored user token:</span>
                    <span>{{ token ?? 'null' }}</span>

                    <span class="font-bold">Stored user email:</span>
                    <span>{{ email ?? 'null' }}</span>

                    <span class="font-bold">Server Identifier:</span>
                    <span>{{ server ?? 'null' }}</span>
                </div>
                <div class="flex flex-row justify-around">
                    <button
                        class="mt-4 bg-white px-4 py-2 text-red-500"
                        @click="clearCredentials()"
                    >
                        Clear Credentials
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref, defineEmits } from 'vue';
    import apiEndpoints from '@/constants/apiEndpoints.js';
    import { useAuthStore } from '@/stores/auth.js';
    import { server_identifier } from '@/AppConfig/index.js';
    import HeadingOne from '@components/text/HeadingOne.vue';
    import { usePlatformStore } from '@/stores/platform.js';
    import { consumer } from '@/services/Api/Consumer.js';
    import { useLocalCache } from '@/composables/localCache';

    const { get } = consumer();

    const { isNativePlatform } = usePlatformStore();

    const { getAuth, removeAuth } = useAuthStore();

    const { invalidateCacheEntry } = useLocalCache();

    // User storage values
    const token = ref(null);
    const email = ref(null);
    const server = ref(server_identifier);

    const testUsers = ref([]);
    const testUser = ref(null);

    const emit = defineEmits([
        'handleTestLoginMobileAppResponse',
        'handleTestLoginSpaResponse',
    ]);

    onMounted(() => {
        enableDebugModeTools();
    });

    async function enableDebugModeTools() {
        await get(apiEndpoints.testUsers).then((response) => {
            testUsers.value = response.data;
        });

        if (isNativePlatform() === true) {
            await getAuth().then((storeAuth) => {
                return (token.value = storeAuth.tokenToReturn), (email.value = storeAuth.emailToReturn);
            });
        }
    }

    async function loginAsTestUser() {
        let debugType = 'spa';

        if (isNativePlatform() === true) {
            debugType = 'mobile_app';
        }

        await get(
            apiEndpoints.testLogin,
            {
                debug_type: debugType,
                email: testUser.value,
            },
            false
        ).then((response) => {
            invalidateCacheEntry('isAuthenticated');

            // Emit an event and handle it in the parent
            if (debugType === 'mobile_app') {
                emit('handleTestLoginMobileAppResponse', response.data);
            } else {
                emit('handleTestLoginSpaResponse');
            }
        });
    }

    function clearCredentials() {
        removeAuth().then(() => {
            token.value = null;
            email.value = null;
        });
    }
</script>
