import { usePlatformStore } from '@/stores/platform.js';
import { v4 as uuidv4 } from 'uuid';

export function mergeMetadataForRequest() {
    function extractCorrelationIdFromPayload(payload) {
        return payload.metadata.correlationId;
    }

    function mergeMetadataIntoPayload(data = {}) {
        const { isNativePlatform, setIsNativePlatform } = usePlatformStore();

        if (isNativePlatform() === null) {
            setIsNativePlatform();
        }

        let correlationId = uuidv4();

        const metadata = {
            isNativePlatform: isNativePlatform(),
            appVersion: process.env.VUE_APP_RELEASE_VERSION,
            correlationId: correlationId,
        };

        if (typeof data === 'object') {
            return { ...data, metadata };
        } else {
            return { data, metadata };
        }
    }

    return { extractCorrelationIdFromPayload, mergeMetadataIntoPayload };
}
