// When we run our Cypress tests, a production build, and therefore the production environment is utilized.
// But, on the first hit of this file when running the tests, the `process.env.NODE_ENV` is development
// And the `VUE_API_ENDPOINT` is undefined. This results in the api_endpoint being set to whatever
// The fallback is set to here. But, when this is read again in subsequent requests, the prod
// Environment is used, resulting in our api_endpoint matching whatever is in the prod env
// This ultimately results in a mismatch between our stubbed network request endpoints
// And the endpoints being hit in the tests, so we aren't able to intercept requests
// I'm setting the fallback url to match what we have in production to eliminate
// This issue for now, though there are a few different ways we might want to
// Solve for this in the future. Sorry, I ran out of words to put here ...
module.exports = {
    release_version: process.env.VUE_APP_RELEASE_VERSION || '0.0.0',
    api_endpoint:
        process.env.VUE_APP_API_ENDPOINT ||
        `http://${window.location.hostname}:${process.env.VUE_APP_API_PORT ?? '80'}/`,

    server_identifier: process.env.VUE_APP_SERVER_IDENTIFIER || 'edu.maricopa.portal',
    gtm_enabled: process.env.VUE_APP_GTM_ENABLED === 'true',
    sentry_enabled: process.env.VUE_APP_SENTRY_ENABLED === 'true',
    sentry_environment: process.env.VUE_APP_SENTRY_ENVIRONMENT || 'local',
    sentry_dsn: process.env.VUE_APP_SENTRY_DSN || '',
    sentry_tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE || 0.1,
    sentry_replaysSessionSampleRate: process.env.VUE_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || 0,
    sentry_replaysOnErrorSampleRate: process.env.VUE_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || 1.0,
};
