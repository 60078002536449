<template>
    <IonPage>
        <IonHeader class="bg-maricopa-blue">
            <IonToolbar class="mx-auto max-w-7xl">
                <IonButtons
                    @click="openMenu()"
                    slot="start"
                    class="ml-3 hidden sm:block"
                >
                    <IonIcon
                        :icon="menu"
                        slot="start"
                    ></IonIcon>
                </IonButtons>
                <IonButtons
                    slot="start"
                    class="sm:hidden"
                >
                    <IonBackButton></IonBackButton>
                </IonButtons>
                <IonTitle mode="ios">{{ pageTitle }}</IonTitle>
                <ProfileOptionsPopoverButton />
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <div class="mx-auto flex min-h-full max-w-7xl flex-col justify-between p-2 px-4 sm:py-4 sm:pb-16">
                <slot></slot>
                <div class="mb-2 mt-2 flex items-center justify-center">
                    <InformationCircleIcon class="mr-1 h-4 w-4 text-maricopa-text-gray" />
                    <p class="text-xs text-maricopa-text-gray">Information in this system may be up to 5 minutes old</p>
                </div>
            </div>
            <PortalFooterDesktop></PortalFooterDesktop>
        </IonContent>
    </IonPage>
</template>

<script setup>
    import {
        IonPage,
        IonHeader,
        IonContent,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonIcon,
        menuController,
        IonBackButton,
    } from '@ionic/vue';
    import { menu } from 'ionicons/icons';
    import PortalFooterDesktop from '@/components/footer/PortalFooterDesktop';
    import { InformationCircleIcon } from '@heroicons/vue/20/solid';
    import ProfileOptionsPopoverButton from '@components/layout/ProfileOptionsPopoverButton.vue';

    defineProps({
        pageTitle: {
            type: String,
            required: true,
        },
    });

    function openMenu() {
        menuController.open('side-menu');
    }
</script>
