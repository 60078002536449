import { ref } from 'vue';
import { Device } from '@awesome-cordova-plugins/device';

async function generateAndReturnDeviceInfo() {
    const deviceInfo = ref({});

    deviceInfo.value.manufacturer = Device.manufacturer;
    deviceInfo.value.model = Device.model;
    deviceInfo.value.platform = Device.platform;

    return generateDeviceInfoName(deviceInfo);
}

async function generateDeviceInfoName(deviceInfo) {
    return deviceInfo.value.manufacturer + ' ' + deviceInfo.value.model + ' - ' + deviceInfo.value.platform;
}

async function getDeviceNickname() {
    // TODO Figure out where we get this information from
}

export { generateAndReturnDeviceInfo, getDeviceNickname };