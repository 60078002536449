import { defineStore } from 'pinia';
import { ref } from 'vue';
import checkBackendConnection from '@/services/Api/BackendConnection.js';

export const useBackendStore = defineStore('backendStore', () => {
    const backendIsOperatingAsExpected = ref(false);

    async function setBackendIsOperatingAsExpected(value) {
        backendIsOperatingAsExpected.value = value;
    }

    async function getBackendIsOperatingAsExpected() {
        if (backendIsOperatingAsExpected.value === false) {
            let backendConnectionStatus = await checkBackendConnection();

            await setBackendIsOperatingAsExpected(backendConnectionStatus);
        }

        return backendIsOperatingAsExpected.value;
    }

    return { setBackendIsOperatingAsExpected, getBackendIsOperatingAsExpected };
});
