// The base url of the Laravel Application
// For local development, this will need to be your "exposed" domain
// So that the mobile app is able to communicate with your backend
import { api_endpoint } from '@/AppConfig/index.js';

const BaseEndpoint = api_endpoint;

const AzureRedirect = 'auth/azure/redirect';
const SanctumCSRFCookie = 'sanctum/csrf-cookie';

export default {
    base: BaseEndpoint,
    azureRedirect: BaseEndpoint + AzureRedirect,
    sanctumCSRFCookie: BaseEndpoint + SanctumCSRFCookie,
};