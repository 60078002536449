import { api_endpoint } from '@/AppConfig/index.js';

// Base Configuration for -> app.com/api/
const ApiIndicator = 'api/';
const BaseApiEndpoint = api_endpoint + ApiIndicator;

// API Debug Endpoints
const TestLogin = 'test/login';
const TestUsers = 'test/users';
const TestDataOptions = 'test/data-options';
const TestData = 'test/data';
const AdminAccessLogin = 'admin/access/login';

// API Endpoints
const AccessTokenValidation = 'validate/personal-access-token';
const SisConnectionCheck = 'student-information-system/check-connection';
const BackendConnectionCheck = 'my-maricopa-student-portal/check-connection';

const User = 'user';
const Constituent = 'constituent';
const Advisors = 'advisors';
const Announcements = 'announcements';
const Finances = 'finances';
const FinancesAwards = 'finances/awards';
const FinancesPayments = 'finances/payments';
const FinancesPaymentsSso = 'finances/payments/sso';
const Grades = 'grades';
const Holds = 'holds';
const Schedule = 'schedule';
const Todos = 'todos';
const Transcripts = 'transcripts';
const UnofficialTranscripts = 'unofficial-transcripts';
const OfficialTranscripts = 'official-transcripts';
const ProductAlertsAuthenticated = 'product-alerts-authenticated';
const ProductAlertsLogin = 'product-alerts-login';
const ImportantLinks = 'important-links';
const Content = 'content';

// Email Triggering Endpoints
const GradesEmail = 'grades/send-to-email';
const ScheduleEmail = 'schedule/send-to-email';
const ScheduleCalendar = 'schedule/send-to-calendar';
const TranscriptsEmail = 'transcripts/send-to-email';

// Printing Based Endpoints
const UnofficialTranscriptsPrint = 'unofficial-transcripts/pdf';

const Logout = 'logout';

export default {
    testLogin: BaseApiEndpoint + TestLogin,
    testUsers: BaseApiEndpoint + TestUsers,
    testDataOptions: BaseApiEndpoint + TestDataOptions,
    testData: BaseApiEndpoint + TestData,
    adminAccessLogin: BaseApiEndpoint + AdminAccessLogin,
    accessTokenValidation: BaseApiEndpoint + AccessTokenValidation,
    sisConnectionCheck: BaseApiEndpoint + SisConnectionCheck,
    backendConnectionCheck: BaseApiEndpoint + BackendConnectionCheck,
    user: BaseApiEndpoint + User,
    constituent: BaseApiEndpoint + Constituent,
    advisors: BaseApiEndpoint + Advisors,
    announcements: BaseApiEndpoint + Announcements,
    finances: BaseApiEndpoint + Finances,
    financesAwards: BaseApiEndpoint + FinancesAwards,
    financesPayments: BaseApiEndpoint + FinancesPayments,
    financesPaymentsSso: BaseApiEndpoint + FinancesPaymentsSso,
    grades: BaseApiEndpoint + Grades,
    gradesSendToEmail: BaseApiEndpoint + GradesEmail,
    holds: BaseApiEndpoint + Holds,
    schedule: BaseApiEndpoint + Schedule,
    scheduleSendToEmail: BaseApiEndpoint + ScheduleEmail,
    scheduleSendToCalendar: BaseApiEndpoint + ScheduleCalendar,
    todos: BaseApiEndpoint + Todos,
    transcripts: BaseApiEndpoint + Transcripts,
    transcriptsSendToEmail: BaseApiEndpoint + TranscriptsEmail,
    unofficialTranscripts: BaseApiEndpoint + UnofficialTranscripts,
    unofficialTranscriptsPrint: BaseApiEndpoint + UnofficialTranscriptsPrint,
    officialTranscripts: BaseApiEndpoint + OfficialTranscripts,
    productAlertsAuthenticated: BaseApiEndpoint + ProductAlertsAuthenticated,
    productAlertsLogin: BaseApiEndpoint + ProductAlertsLogin,
    importantLinks: BaseApiEndpoint + ImportantLinks,
    content: BaseApiEndpoint + Content,
    logout: BaseApiEndpoint + Logout,
};
