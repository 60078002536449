<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 39.1 38.9"
    >
        <path
            fill="currentColor"
            d="M34,20.9l-9,14.4l-4.6-9l12-20.5c-7.5-6.7-19-5.9-25.7,1.6c-4.6,5.2-5.8,12.5-3.2,18.9L17,3l7.3,13.4l-4.8,8.3
	L17,21L9.1,33.7c8,6.1,19.4,4.7,25.5-3.3c1-1.3,1.8-2.6,2.4-4.1L34,20.9z"
        ></path>
    </svg>
</template>

<script setup></script>
