/* eslint no-console: ["warn", { allow: ["error"] }] */
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { NativeBiometric } from '@capgo/capacitor-native-biometric';
import { server_identifier } from '@/AppConfig/index.js';
import { manuallyCaptureException } from '@/helpers/manuallyCaptureException';
import { usePlatformStore } from '@/stores/platform.js';
import { Capacitor } from '@capacitor/core';

const ignoredErrorsForIOS = [
    'The operation couldn’t be completed',
];

const ignoredErrorsForAndroid = [
    'No credentials found',
];

export const useAuthStore = defineStore('auth', () => {
    const { isNativePlatform } = usePlatformStore();
    const token = ref(null);
    const email = ref(null);

    async function setAuth(emailToSet, tokenToSet) {
        email.value = emailToSet;
        token.value = tokenToSet;

        if (token.value !== null) {
            try {
                await NativeBiometric.setCredentials({
                    username: email.value,
                    password: token.value,
                    server: server_identifier,
                });
            } catch (error) {
                if (shouldReportError(error.message) === true) {
                    manuallyCaptureException(error, {
                        tags: {
                            type: 'NativeBiometric',
                            method: 'setAuth',
                        },
                    });
                }
            }
        }
    }

    async function getAuth() {
        let tokenToReturn = token.value;
        let emailToReturn = email.value;

        if (isNativePlatform() === true) {
            if (tokenToReturn === null) {
                try {
                    const credentials = await NativeBiometric.getCredentials({
                        server: server_identifier,
                    });

                    tokenToReturn = token.value = credentials.password;
                    emailToReturn = email.value = credentials.username;
                } catch (error) {
                    if ((await shouldReportError(error.message)) === true) {
                        manuallyCaptureException(error, {
                            tags: {
                                type: 'NativeBiometric',
                                method: 'getAuth',
                            },
                        });
                    }
                }
            }
        }

        await setAuth(emailToReturn, tokenToReturn);

        return { tokenToReturn, emailToReturn };
    }

    async function removeAuth() {
        token.value = null;
        email.value = null;

        NativeBiometric.deleteCredentials({
            server: server_identifier,
        });
    }

    async function shouldReportError(error) {
        let errorsToIgnore = [];

        if (Capacitor.getPlatform() === 'ios') {
            errorsToIgnore = ignoredErrorsForIOS;
        } else if (Capacitor.getPlatform() === 'android') {
            errorsToIgnore = ignoredErrorsForAndroid;
        }

        for (let i = 0; i < errorsToIgnore.length; i++) {
            if (error.includes(errorsToIgnore[i])) {
                return false;
            }
        }

        return true;
    }

    return { token, email, getAuth, setAuth, removeAuth };
});
