import { defineStore } from 'pinia';

export const useDebugStore = defineStore('debug', {
    state: () => {
        return {
            debug: process.env.VUE_APP_DEBUG_MODE === 'true',
            testDataConstituent: process.env.VUE_APP_TEST_DATA_CONSTITUENT,
            testDataAdvisors: process.env.VUE_APP_TEST_DATA_ADVISORS,
            testDataAnnouncements: process.env.VUE_APP_TEST_DATA_ANNOUNCEMENTS,
            testDataFinancesAwards: process.env.VUE_APP_TEST_DATA_FINANCES_AWARDS,
            testDataFinancesPayments: process.env.VUE_APP_TEST_DATA_FINANCES_PAYMENTS,
            testDataGrades: process.env.VUE_APP_TEST_DATA_GRADES,
            testDataHolds: process.env.VUE_APP_TEST_DATA_HOLDS,
            testDataSchedule: process.env.VUE_APP_TEST_DATA_SCHEDULE,
            testDataTodos: process.env.VUE_APP_TEST_DATA_TODOS,
            testDataTranscripts: process.env.VUE_APP_TEST_DATA_TRANSCRIPTS,
            testDataUnofficialTranscripts: process.env.VUE_APP_TEST_DATA_UNOFFICIAL_TRANSCRIPTS,
            testDataOfficialTranscripts: process.env.VUE_APP_TEST_DATA_OFFICIAL_TRANSCRIPTS,
            testDataProductAlerts: process.env.VUE_APP_TEST_DATA_PRODUCT_ALERTS,
        };
    },
});
