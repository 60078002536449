/* eslint no-console: ["warn", { allow: ["error"] }] */
import axios from '@/globals/Axios.js';
import apiEndpoints from '@/constants/apiEndpoints.js';
import { useAuthStore } from '@/stores/auth.js';
import { useLocalCache } from '@/composables/localCache';
import * as Sentry from '@sentry/vue';
import { usePlatformStore } from '@/stores/platform.js';
import { mergeMetadataForRequest } from '@/helpers/mergeMetadataForRequest';
import { manuallyCaptureException } from '@/helpers/manuallyCaptureException';

async function determineIfUserIsAuthenticated() {
    const { setCachedData, getCachedData } = useLocalCache();

    const { mergeMetadataIntoPayload, extractCorrelationIdFromPayload } = mergeMetadataForRequest();

    return getCachedData('isAuthenticated', async function () {
        const { isNativePlatform } = usePlatformStore();
        const { getAuth } = useAuthStore();

        let token = null;

        if (isNativePlatform() === true) {
            token = await getAuth().then((storeToken) => {
                return storeToken.tokenToReturn;
            });
        }

        if (isNativePlatform() === true && token === null) {
            return false;
        }

        let payload = mergeMetadataIntoPayload();

        return await axios
            .get(apiEndpoints.user, {
                headers: { Authorization: `Bearer ${token}` },
                params: payload,
            })
            .then((response) => {
                const isAuthenticated = response.status === 200;
                Sentry.setUser(null);

                if (isAuthenticated) {
                    Sentry.setUser({ id: response.data.id });
                    setCachedData('isAuthenticated', true);
                }

                return isAuthenticated;
            })
            .catch((error) => {
                if (window.unloading && error.code === 'ERR_NETWORK') {
                    return false;
                }

                if (error?.response?.status !== 401) {
                    manuallyCaptureException(error, {
                        tags: {
                            type: 'Axios',
                            endpoint: apiEndpoints.user,
                            correlationId: extractCorrelationIdFromPayload(payload),
                        },
                    });
                }

                return false;
            });
    });
}

export default determineIfUserIsAuthenticated;
