<template>
    <div
        v-if="loadingStore.loading"
        class="flex h-screen w-full"
    >
        <div
            class="fixed bottom-0 left-0 right-0 top-0 z-40 flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-gray-700 opacity-95"
        ></div>
        <div class="z-50 mt-8 flex h-screen w-full flex-col items-center justify-center">
            <IonSpinner
                class="mb-6 scale-150 text-white"
                name="crescent"
            />
            <HeadingThree class="text-white">Logging out...</HeadingThree>
        </div>
    </div>
</template>

<script setup>
    import { IonSpinner } from '@ionic/vue';
    import HeadingThree from '@components/text/HeadingThree.vue';
    import { useLoadingOverlay } from '@/stores/loadingOverlay';

    const loadingStore = useLoadingOverlay();
</script>
