<template>
    <div>
        <IonItem
            button
            :detail="true"
            :detail-icon="open ? chevronDown : chevronForward"
            @click="clicked"
            class="sub-menu-item"
        >
            <Component
                v-bind:is="icon"
                class="mr-2 h-6 w-6 text-maricopa-text-gray"
            ></Component>
            <IonLabel class="text-maricopa-text-gray">{{ label }}</IonLabel>
        </IonItem>
        <slot v-if="open"></slot>
    </div>
</template>

<script setup>
    import { IonItem, IonLabel, menuController } from '@ionic/vue';
    import { ref } from 'vue';
    import { chevronForward, chevronDown } from 'ionicons/icons';
    import { useRouter } from 'vue-router';

    const props = defineProps({
        label: {
            type: String,
            required: true,
        },
        icon: {
            required: true,
        },
        route: {
            type: Object,
            required: false,
        },
    });

    const router = useRouter();

    const open = ref(false);

    const clicked = (e) => {
        if (props.route && e.target.tagName !== 'ION-ITEM') {
            router.push(props.route);
            menuController.close('side-menu');
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        open.value = !open.value;
    };
</script>
