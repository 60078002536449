import apiEndpoints from '@/constants/apiEndpoints.js';
import { consumer } from '@/services/Api/Consumer.js';

async function checkSisConnection() {
    const { get } = consumer();

    return await get(apiEndpoints.sisConnectionCheck, null, false, true)
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
}

export default checkSisConnection;
