/* eslint no-console: ["warn", { allow: ["error"] }] */

import { useRouter } from 'vue-router';
import { useIsExternalLink } from '@/composables/isExternalLink';
import { manuallyCaptureException } from '@/helpers/manuallyCaptureException';
import { usePlatformStore } from '@/stores/platform.js';

export const useNavigate = () => {
    const { isNativePlatform } = usePlatformStore();
    const router = useRouter();

    const navigate = async (urlOrRoute, newTab = false) => {
        if (typeof urlOrRoute === 'string' && useIsExternalLink(urlOrRoute)) {
            if (isNativePlatform()) {
                document.location.href = urlOrRoute;
                return;
            }

            window.open(urlOrRoute, newTab ? '_blank' : '_self');
            return;
        }

        router.push(urlOrRoute).catch((error) => manuallyCaptureException(error, { tags: { type: 'Router' } }));
    };

    return { navigate };
};
