import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Capacitor } from '@capacitor/core';

export const usePlatformStore = defineStore('nativePlatform', () => {
    const platformNative = ref(null);

    function setIsNativePlatform() {
        platformNative.value = Capacitor.isNativePlatform();
    }

    function isNativePlatform() {
        return platformNative.value;
    }

    function clearPlatform() {
        platformNative.value = null;
    }

    return { platformNative, setIsNativePlatform, isNativePlatform, clearPlatform };
});
