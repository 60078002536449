/* eslint-disable */
/* eslint no-console: ["warn", { allow: ["error"] }] */

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import { createGtm } from '@gtm-support/vue-gtm';
import router from './router';
import determineIfUserIsAuthenticated from '@/services/User/DetermineIfUserIsAuthenticated.js';
import * as Sentry from '@sentry/vue';
import { manuallyCaptureException } from '@/helpers/manuallyCaptureException';

import { IonicVue } from '@ionic/vue';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import {
    release_version,
    gtm_enabled,
    sentry_enabled,
    sentry_environment,
    sentry_dsn,
    sentry_tracesSampleRate,
    sentry_replaysSessionSampleRate,
    sentry_replaysOnErrorSampleRate,
} from '@/AppConfig/index.js';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/tailwind-output.css';

/* Layouts */
import MainLayout from '@/components/layout/MainLayout';
import DashboardLayout from '@/components/layout/DashboardLayout';

import { useLocalCache } from '@/composables/localCache';

/* Stores */
import { usePlatformStore } from '@/stores/platform.js';
import { useBackendStore } from '@/stores/backend.js';

window.unloading = false;

addEventListener('beforeunload', (event) => {
    window.unloading = true;
});

addEventListener('readystatechange', (event) => {
    if (event.target.readyState === 'loading') {
        window.unloading = false;
    }
});

addEventListener('unhandledrejection', (event) => {
    let ignoreError = false;

    // Ignore ChunkLoadError errors that occur during page unload
    if (event.reason?.name === 'ChunkLoadError' && window.unloading === true) {
        ignoreError = true;
    }

    if (!ignoreError) {
        manuallyCaptureException(event, {
            tags: {
                type: 'unhandledrejection',
            },
        });
    }

    // Prevent the rejection from bubbling up into the browser's console as an unhandled promise rejection
    event.preventDefault();
});

const pinia = createPinia();

router.beforeEach(async (to) => {
    const { getCachedData } = useLocalCache();

    let isAuthenticated = await getCachedData('isAuthenticated');

    if (isAuthenticated !== true) {
        isAuthenticated = await determineIfUserIsAuthenticated();
    }

    if (!isAuthenticated && to.name === 'administrator-access') {
        return;
    }

    if (!isAuthenticated && to.name !== 'login') {
        return { name: 'login' };
    }
});

const app = createApp(App)
    .use(IonicVue)
    .use(pinia)
    .use(router)
    .use(
        createGtm({
            id: 'GTM-NMCRDDH',
            enabled: gtm_enabled,
            loadScript: true,
            vueRouter: router,
        })
    );

app.component('MainLayout', MainLayout);
app.component('DashboardLayout', DashboardLayout);

document.addEventListener(
    'deviceready',
    function () {
        if (Capacitor.isNativePlatform()) {
            StatusBar.setStyle({ style: Style.Dark }).catch(function (error) {
                manuallyCaptureException(error, {
                    tags: {
                        type: 'StatusBar',
                        method: 'setStyle',
                    },
                });
            });

            if (Capacitor.getPlatform() === 'android') {
                StatusBar.setBackgroundColor({
                    color: '#0c2340',
                }).catch(function (error) {
                    manuallyCaptureException(error, {
                        tags: {
                            type: 'StatusBar',
                            method: 'setBackgroundColor',
                        },
                    });
                });

                StatusBar.setOverlaysWebView({ overlay: false }).catch(function (error) {
                    console.error(error);
                });
            }
        }
    },
    false
);

if (sentry_enabled === true) {
    Sentry.init({
        app,
        dsn: sentry_dsn,
        release: release_version,
        environment: sentry_environment,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay({
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
        trackComponents: true,
        tracesSampleRate: Number(sentry_tracesSampleRate),
        replaysSessionSampleRate: Number(sentry_replaysSessionSampleRate),
        replaysOnErrorSampleRate: Number(sentry_replaysOnErrorSampleRate),
    });
}

import checkBackendConnection from '@/services/Api/BackendConnection.js';

// Before mounting the app, let's ensure the backend is operating as expected
ensureBackendIsOperating();

async function ensureBackendIsOperating() {
    const { setBackendIsOperatingAsExpected } = useBackendStore();

    let backendConnectionStatus = await checkBackendConnection();

    setBackendIsOperatingAsExpected(backendConnectionStatus);
}

router
    .isReady()
    .then(() => {
        app.mount('#app');
    })
    .then(() => {
        const { isNativePlatform, setIsNativePlatform } = usePlatformStore();

        if (isNativePlatform() === null) {
            setIsNativePlatform();
        }
    });
