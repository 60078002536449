<template>
    <div
        v-if="isNativePlatform() === false"
        class="fixed top-16 z-50 w-full cursor-pointer rounded-sm bg-gray-200 hover:bg-gray-300 lg:hidden"
    >
        <div class="flex w-full flex-col px-4 py-2 text-center">
            <div>
                <HeadingThree>It's better in the app! {{ appStoreToShow.text }}</HeadingThree>
            </div>

            <div class="flex justify-center">
                <div class="mt-4 flex items-center justify-center">
                    <DownloadOnTheAppStore
                        v-show="['ios', 'mac', 'unknown'].includes(appStoreToShow.os)"
                        class="w-32"
                        @click="windowOpen('https://apps.apple.com/us/app/my-maricopa-student-portal/id6448309833')"
                    />
                </div>
                <div class="mt-4 flex items-center justify-center">
                    <GetItOnGooglePlay
                        v-show="['android', 'unknown'].includes(appStoreToShow.os)"
                        class="w-32"
                        @click="windowOpen('https://play.google.com/store/apps/details?id=edu.maricopa.portal')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { Device } from '@capacitor/device';
    import HeadingThree from '@/components/text/HeadingThree.vue';
    import DownloadOnTheAppStore from '@/components/svg/DownloadOnTheAppStore.vue';
    import GetItOnGooglePlay from '@/components/svg/GetItOnGooglePlay.vue';
    import { usePlatformStore } from '@/stores/platform.js';
    import { manuallyCaptureException } from '@/helpers/manuallyCaptureException';

    const { isNativePlatform } = usePlatformStore();

    getDeviceInfo();

    const appStoreToShow = ref({
        os: 'unknown',
        text: 'Get it on your app store today!',
    });

    async function getDeviceInfo() {
        await Device.getInfo()
            .then((info) => {
                switch (info.operatingSystem) {
                    case 'android':
                        appStoreToShow.value.text = 'Get it on Google Play today!';
                        break;
                    case 'ios':
                    case 'mac':
                        appStoreToShow.value.text = 'Download on the App Store today!';
                        break;
                }

                appStoreToShow.value.os = info.operatingSystem;
            })
            .catch((error) => {
                if (error.name === 'ChunkLoadError' && window.unloading === true) {
                    return false;
                }

                manuallyCaptureException(error, {
                    tags: {
                        type: 'Device',
                        method: 'getInfo',
                    },
                });
            });
    }

    const windowOpen = (url) => {
        window.open(url, '_blank');
    };
</script>
