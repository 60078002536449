import { createRouter, createWebHistory } from '@ionic/vue-router';
import LoginPage from '@/views/LoginPage.vue';

const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginPage,
    },
    {
        path: '/administrator-access',
        name: 'administrator-access',
        component: () => import('@/views/AdministratorAccessPage.vue'),
    },
    {
        path: '/logout',
        name: 'logout',
        redirect: '/',
    },
    {
        path: '/root',
        name: 'root',
        redirect: '/dashboard',
        component: () => import('@/views/TabNavigation.vue'),
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('@/views/DashboardPage.vue'),
            },
            {
                name: 'profile',
                path: '/profile',
                component: () => import('@/views/profile/ProfilePageWrapper.vue'),
            },
            {
                name: 'help',
                path: '/help',
                component: () => import('@/views/HelpPage.vue'),
            },
            {
                name: 'quick-links',
                path: '/quick-links',
                component: () => import('@/views/quick-links/QuickLinksPageWrapper.vue'),
            },
            {
                name: 'plan',
                path: '/plan',
                component: () => import('@/views/plan/PlanPageWrapper.vue'),
            },
            {
                name: 'advisors',
                path: '/advisors',
                component: () => import('@/views/advisors/AdvisorsPageWrapper.vue'),
            },
            // {
            //     name: 'cases',
            //     path: '/cases',
            //     component: () => import('@/views/cases/IndexPage.vue'),
            // },
            // {
            //     name: 'cases.show',
            //     path: '/cases/:caseId',
            //     component: () => import('@/views/cases/ShowPage.vue'),
            // },
            // {
            //     name: 'knowledge-base',
            //     path: '/knowledge-base',
            //     component: () => import('@/views/KnowledgeBasePage.vue'),
            // },
            {
                name: 'finances',
                path: '/finances',
                component: () => import('@/views/finances/FinancesPage.vue'),
            },
            {
                name: 'finances.awards',
                path: '/finances/awards',
                component: () => import('@/views/finances/awards/FinancesAwardsPageWrapper.vue'),
            },
            {
                name: 'finances.payments',
                path: '/finances/payments',
                component: () => import('@/views/finances/payments/FinancesPaymentsPageWrapper.vue'),
            },
            {
                name: 'holds',
                path: '/holds',
                component: () => import('@/views/holds/HoldsPageWrapper.vue'),
            },
            {
                name: 'holds.show',
                path: '/holds/:code',
                component: () => import('@/views/holds/HoldsShowPageWrapper.vue'),
                props: true,
            },
            {
                name: 'grades',
                path: '/grades',
                component: () => import('@/views/grades/GradesPageWrapper.vue'),
            },
            {
                name: 'transcripts',
                path: '/transcripts',
                component: () => import('@/views/transcripts/TranscriptsPageWrapper.vue'),
            },
            {
                name: 'todos',
                path: '/todos',
                component: () => import('@/views/todos/TodosPageWrapper.vue'),
            },
            {
                name: 'todos.show',
                path: '/todos/:code',
                component: () => import('@/views/todos/TodosShowPageWrapper.vue'),
                props: true,
            },
            {
                name: 'schedule',
                path: '/schedule',
                component: () => import('@/views/schedule/SchedulePageWrapper.vue'),
            },
            {
                name: 'announcements',
                path: '/announcements',
                component: () => import('@/views/announcements/AnnouncementsPageWrapper.vue'),
            },
            {
                name: 'announcements.show',
                path: '/announcements/:id/:name',
                component: () => import('@/views/announcements/AnnouncementsShowPageWrapper.vue'),
                props: true,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
