<template>
    <IonContent>
        <IonList
            mode="ios"
            lines="full"
        >
            <IonItem
                button
                :detail="false"
                :router-link="{ name: 'profile' }"
            >
                <Component
                    v-bind:is="UserCircleIcon"
                    class="mr-2 h-6 w-6"
                ></Component>
                <IonLabel>Profile</IonLabel>
            </IonItem>
            <IonItem
                button
                :detail="false"
                @click="logout"
            >
                <Component
                    v-bind:is="ArrowRightOnRectangleIcon"
                    class="mr-2 h-6 w-6"
                ></Component>
                <IonLabel>Logout</IonLabel>
            </IonItem>
        </IonList>
    </IonContent>
</template>

<script setup>
    import { IonContent, IonList, IonItem, IonLabel } from '@ionic/vue';
    import { ArrowRightOnRectangleIcon, UserCircleIcon } from '@heroicons/vue/24/outline';
    import { consumer } from '@/services/Api/Consumer';
    import { useAuthStore } from '@/stores/auth';
    import apiEndpoints from '@/constants/apiEndpoints';
    import { useApiCacheStore } from '@/stores/apiCache';
    import { useLocalCache } from '@/composables/localCache';
    import { useNavigate } from '@/composables/navigate';
    const { removeAllApiCache } = useApiCacheStore();
    const { clearCachedData } = useLocalCache();
    const { navigate } = useNavigate();
    const { get } = consumer();
    const { removeAuth } = useAuthStore();
    import { usePlatformStore } from '@/stores/platform.js';
    import { useLoadingOverlay } from '@/stores/loadingOverlay';
    import { manuallyCaptureException } from '@/helpers/manuallyCaptureException';

    const { isNativePlatform } = usePlatformStore();

    const logout = async () => {
        const loadingOverlay = useLoadingOverlay();

        loadingOverlay.setLoading(true);

        try {
            await removeAllApiCache();

            await clearCachedData();

            if (isNativePlatform() === true) {
                await removeAuth();
            }

            await get(apiEndpoints.logout).then((response) => {
                navigate(response.data.redirect);
            });
        } catch (error) {
            manuallyCaptureException(error, {
                tags: {
                    method: 'logout',
                },
            });
        } finally {
            loadingOverlay.setLoading(false);
        }
    };
</script>
