export function formulateCacheKeyFor(endpoint, data) {
    let cacheKey = endpoint.split('api/')[1];

    if (data) {
        let dataCacheString = '';

        if (typeof data === 'object') {
            Object.values(data).forEach(function(value) {
                dataCacheString += `.${value}`;
            });
        } else {
            dataCacheString = data;
        }

        cacheKey = cacheKey + dataCacheString;
    }

    return cacheKey;
}