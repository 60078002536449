<template>
    <IonButtons
        class="z-10 mr-3 cursor-pointer"
        slot="end"
        @click="openPopover"
    >
        <UserCircleIcon class="h-6 w-6" />
    </IonButtons>
</template>

<script setup>
    import { IonButtons, popoverController } from '@ionic/vue';
    import { UserCircleIcon } from '@heroicons/vue/24/outline';
    import ProfileOptionsPopover from '@components/layout/ProfileOptionsPopover.vue';

    const openPopover = async (event) => {
        const popover = await popoverController.create({
            component: ProfileOptionsPopover,
            event: event,
            side: 'bottom',
            dismissOnSelect: true,
            mode: 'ios',
        });

        await popover.present();
    };
</script>
