<template>
    <div class="mx-2 md:mx-0">
        <div class="rounded border-l-4 border-red-400 bg-red-50 p-4">
            <div class="flex items-center">
                <IonIcon
                    class="h-8 w-8 text-red-500"
                    :icon="errorIcon"
                ></IonIcon>
                <div class="ml-3">
                    <ParagraphComponent>
                        {{ message }}
                    </ParagraphComponent>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { IonIcon } from '@ionic/vue';
    import { alertCircleOutline } from 'ionicons/icons';
    import ParagraphComponent from '@components/text/ParagraphComponent.vue';

    const errorIcon = ref(alertCircleOutline);

    defineProps({
        message: {
            type: String,
            required: true,
        },
    });
</script>
