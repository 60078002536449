import axios from 'axios';
import axiosRetry from 'axios-retry';

const axiosInstance = axios.create({
    withCredentials: true,
});

axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axiosInstance.defaults.headers.common['Accept'] = 'application/json';
axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axiosRetry(axiosInstance);

export default axiosInstance;
