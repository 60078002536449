<template>
    <IonPage>
        <IonHeader class="bg-maricopa-blue">
            <IonToolbar class="mx-auto max-w-7xl">
                <IonButtons
                    @click="openMenu()"
                    slot="start"
                    class="ml-3 hidden sm:block"
                >
                    <IonIcon
                        :icon="menu"
                        slot="start"
                    ></IonIcon>
                </IonButtons>
                <IonTitle
                    slot="start"
                    mode="ios"
                    class="text-white"
                >
                    <MaricopaLogoFull />
                </IonTitle>
                <ProfileOptionsPopoverButton />
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <slot></slot>
        </IonContent>
    </IonPage>
</template>

<script setup>
    import {
        IonPage,
        IonHeader,
        IonContent,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonIcon,
        menuController,
    } from '@ionic/vue';
    import { menu } from 'ionicons/icons';
    import MaricopaLogoFull from '@/components/svg/MaricopaLogoFull';
    import ProfileOptionsPopoverButton from '@components/layout/ProfileOptionsPopoverButton.vue';

    defineProps({
        pageTitle: {
            type: String,
            required: true,
        },
    });

    function openMenu() {
        menuController.open('side-menu');
    }
</script>
