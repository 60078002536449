import { Storage } from '@ionic/storage';

export function useLocalCache() {
    const CACHE_KEY = '_maricopacache_';

    async function getStorageInstance() {
        const store = new Storage();

        return await store.create();
    }

    /* TTL is in seconds */
    async function setCachedData(key, data, ttl = 3600) {
        const store = await getStorageInstance();

        const cacheKey = `${CACHE_KEY}${key}`;

        const validUntil = new Date().getTime() + ttl * 1000;

        return store.set(cacheKey, { validUntil, data });
    }

    async function getCachedData(key, closure = null) {
        const store = await getStorageInstance();

        const cacheKey = `${CACHE_KEY}${key}`;

        const currentTime = new Date().getTime();

        const storedValue = await store.get(cacheKey);

        if (!storedValue) {
            if (closure != null) {
                return closure();
            }

            return null;
        } else if (storedValue.validUntil < currentTime) {
            await store.remove(cacheKey);

            if (closure != null) {
                return closure();
            }

            return null;
        } else {
            return storedValue.data;
        }
    }

    async function invalidateCacheEntry(key) {
        const store = await getStorageInstance();

        const cacheKey = `${CACHE_KEY}${key}`;

        await store.remove(cacheKey);
    }

    async function clearCachedData() {
        const store = await getStorageInstance();

        const keys = await store.keys();

        keys.map(async (key) => {
            if (key.startsWith(CACHE_KEY)) {
                await store.remove(key);
            }
        });
    }

    return { setCachedData, getCachedData, invalidateCacheEntry, clearCachedData };
}
