import apiEndpoints from '@/constants/apiEndpoints.js';
import { useAuthStore } from '@/stores/auth.js';
import { ref } from 'vue';
import { consumer } from '@/services/Api/Consumer.js';

async function validateToken(deviceName) {
    const { get } = consumer();

    const { getAuth } = useAuthStore();

    const token = ref(null);
    const email = ref(null);
    const hasValidToken = ref(false);

    let storeAuth = await getAuth().then((storeAuth) => {
        return storeAuth;
    });

    email.value = storeAuth.emailToReturn;
    token.value = storeAuth.tokenToReturn;

    if (token.value === null || token.value === undefined || token.value === '') {
        return false;
    }

    await get(
            apiEndpoints.accessTokenValidation, {
                token: token.value,
                email: email.value,
                deviceName: deviceName,
            },
            false,
            true
        )
        .then((response) => {
            if (response.data.tokenValidated === true) {
                hasValidToken.value = true;
            }
        })
        .catch(() => {
            hasValidToken.value = false;
        });

    return hasValidToken.value;
}

export default validateToken;