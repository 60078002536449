<template>
    <IonMenu
        menu-id="side-menu"
        content-id="main-content"
        :disabled="isNativePlatform()"
    >
        <IonHeader>
            <IonToolbar>
                <IonTitle>Welcome!</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonList class="text-maricopa-blue">
                <IonItem
                    button
                    @click="menuNavigation({ name: 'dashboard' })"
                >
                    <Component
                        v-bind:is="HomeIcon"
                        class="mr-2 h-6 w-6 text-maricopa-text-gray"
                    ></Component>
                    <IonLabel class="text-maricopa-text-gray">Dashboard</IonLabel>
                </IonItem>
                <IonItem
                    button
                    @click="menuNavigation({ name: 'schedule' })"
                >
                    <Component
                        v-bind:is="CalendarDaysIcon"
                        class="mr-2 h-6 w-6 text-maricopa-text-gray"
                    ></Component>
                    <IonLabel class="text-maricopa-text-gray">Schedule</IonLabel>
                </IonItem>
                <IonItem
                    button
                    @click="menuNavigation({ name: 'advisors' })"
                >
                    <Component
                        v-bind:is="LifebuoyIcon"
                        class="mr-2 h-6 w-6 text-maricopa-text-gray"
                    ></Component>
                    <IonLabel class="text-maricopa-text-gray">Advisors</IonLabel>
                </IonItem>
                <SubMenu
                    label="Finances"
                    :icon="CurrencyDollarIcon"
                    :route="{ name: 'finances' }"
                >
                    <IonItem
                        button
                        @click="menuNavigation({ name: 'finances.awards' })"
                    >
                        <Component
                            v-bind:is="TrophyIcon"
                            class="ml-8 mr-2 h-6 w-6 text-maricopa-text-gray"
                        ></Component>
                        <IonLabel class="text-maricopa-text-gray">Awards</IonLabel>
                    </IonItem>
                    <IonItem
                        button
                        @click="menuNavigation({ name: 'finances.payments' })"
                    >
                        <Component
                            v-bind:is="CurrencyDollarIcon"
                            class="ml-8 mr-2 h-6 w-6 text-maricopa-text-gray"
                        ></Component>
                        <IonLabel class="text-maricopa-text-gray">Payments</IonLabel>
                    </IonItem>
                </SubMenu>
                <IonItem
                    button
                    @click="menuNavigation({ name: 'holds' })"
                >
                    <Component
                        v-bind:is="PauseIcon"
                        class="mr-2 h-6 w-6 text-maricopa-text-gray"
                    ></Component>
                    <IonLabel class="text-maricopa-text-gray">Holds</IonLabel>
                </IonItem>
                <IonItem
                    button
                    @click="menuNavigation({ name: 'grades' })"
                >
                    <Component
                        v-bind:is="AcademicCapIcon"
                        class="mr-2 h-6 w-6 text-maricopa-text-gray"
                    ></Component>
                    <IonLabel class="text-maricopa-text-gray">Grades</IonLabel>
                </IonItem>
                <IonItem
                    button
                    @click="menuNavigation({ name: 'transcripts' })"
                >
                    <Component
                        v-bind:is="BookOpenIcon"
                        class="mr-2 h-6 w-6 text-maricopa-text-gray"
                    ></Component>
                    <IonLabel class="text-maricopa-text-gray">Transcripts</IonLabel>
                </IonItem>
                <IonItem
                    button
                    @click="menuNavigation({ name: 'todos' })"
                >
                    <Component
                        v-bind:is="ClipboardDocumentListIcon"
                        class="mr-2 h-6 w-6 text-maricopa-text-gray"
                    ></Component>
                    <IonLabel class="text-maricopa-text-gray">To-Dos</IonLabel>
                </IonItem>
                <IonItem
                    button
                    @click="menuNavigation({ name: 'announcements' })"
                >
                    <Component
                        v-bind:is="MegaphoneIcon"
                        class="mr-2 h-6 w-6 text-maricopa-text-gray"
                    ></Component>
                    <IonLabel class="text-maricopa-text-gray">Notices</IonLabel>
                </IonItem>
                <IonItem
                    button
                    @click="menuNavigation('https://google.maricopa.edu', true)"
                >
                    <Component
                        v-bind:is="EnvelopeIcon"
                        class="mr-2 h-6 w-6 text-maricopa-text-gray"
                    ></Component>
                    <IonLabel class="text-maricopa-text-gray">Email</IonLabel>
                </IonItem>
                <SubMenu
                    label="Quick Links"
                    :icon="LinkIcon"
                >
                    <IonItem
                        v-for="(link, index) in importantLinks"
                        :key="index"
                        button
                        :href="link.url"
                        :target="link.new_tab ? '_blank' : '_self'"
                    >
                        <IonLabel class="pl-8 text-maricopa-text-gray">{{ link.name }}</IonLabel>
                    </IonItem>
                </SubMenu>
                <IonItem
                    button
                    @click="menuNavigation({ name: 'logout' })"
                >
                    <Component
                        v-bind:is="ArrowRightOnRectangleIcon"
                        class="mr-2 h-6 w-6 text-maricopa-text-gray"
                    ></Component>
                    <IonLabel class="text-maricopa-text-gray">Logout</IonLabel>
                </IonItem>
            </IonList>
        </IonContent>
    </IonMenu>
</template>
<script setup>
    import { onMounted, ref } from 'vue';
    import {
        IonMenu,
        IonHeader,
        IonContent,
        IonToolbar,
        IonTitle,
        IonItem,
        IonList,
        IonLabel,
        menuController,
    } from '@ionic/vue';
    import {
        HomeIcon,
        AcademicCapIcon,
        LifebuoyIcon,
        CurrencyDollarIcon,
        PauseIcon,
        BookOpenIcon,
        ClipboardDocumentListIcon,
        CalendarDaysIcon,
        MegaphoneIcon,
        EnvelopeIcon,
        ArrowRightOnRectangleIcon,
        LinkIcon,
        TrophyIcon,
    } from '@heroicons/vue/24/outline';
    import SubMenu from '@components/SubMenu.vue';
    import apiEndpoints from '@/constants/apiEndpoints';
    import { useNavigate } from '@/composables/navigate';
    import { usePlatformStore } from '@/stores/platform.js';
    import { useBackendStore } from '@/stores/backend.js';
    import { consumer } from '@/services/Api/Consumer.js';

    const { get } = consumer();

    const { isNativePlatform } = usePlatformStore();
    const { getBackendIsOperatingAsExpected } = useBackendStore();

    const { navigate } = useNavigate();

    function menuNavigation(urlOrRoute, newTab = false) {
        menuController.close('side-menu');

        navigate(urlOrRoute, newTab);
    }

    const importantLinks = ref([]);

    const getImportantLinks = () => {
        get(apiEndpoints.importantLinks).then((response) => {
            importantLinks.value = response.data.data;
        });
    };

    onMounted(() => {
        getBackendIsOperatingAsExpected().then((backendOperationalStatus) => {
            if (backendOperationalStatus === true) {
                getImportantLinks();
            }
        });
    });
</script>
